export default [
  {
    text: 'Dashboard',
    icon: 'mdi-view-dashboard',
    link: '/admin',
  },
  {
    text: 'Produtos',
    icon: 'mdi-package-variant-closed',
    link: '/admin/products',
  },
  {
    text: 'Obituário',
    icon: 'mdi-format-list-text',
    link: '/admin/obituary',
  },
  {
    text: 'Usuários',
    icon: 'mdi-account',
    link: '/admin/users',
  },
]
